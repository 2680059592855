import * as React from "react";
import { MainLayout, Sticky } from "wmk-layout";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";

/* Feed this to your header and/or footer once data is queried */
//import { MenuData, HyperLink } from "wmk-menu";
import TypekitFont from "./TypekitFont";

const Layout = ({
  children
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <>
      <TypekitFont id="drz2gny" />
      <MainLayout
        Header={
          <Sticky>
            {/* this way you can pass query
              data to this comp. Turn footer into named or
              anonymous function to do the same*/}
            <Header />
          </Sticky>
        }
        Footer={<Footer />}>
        {children}
      </MainLayout>
    </>
  );
};

export default Layout;
