import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MenuQuery } from "../../../fragments/NodeMenuFields";
import { SiteMetaDataFields } from "../../../fragments/NodeSiteMetadataFields";
import FooterColumn from "./FooterCol";
import { colors } from "../../ui/colors";
import Logotype from "../../ui/Logotype";
import FooterButtons from "./FooterBtns";
import styled from "styled-components";
import { ContentfulImageQuery, Img, WmkImage } from "wmk-image";
import { Typography } from "../../ui/Typography";
import { TextLink } from "../../ui/TextLink";

const StyledFooterRow = styled(Row)`
  @media screen and (max-width: 740px) {
    .col-md-auto {
      padding: 2vh 0;
    }
  }
`;

const WatermarkCol = styled(Col)`
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  padding-left: 0 !important;
  padding-top: 1rem;
  position: relative;
  left: 2%;
  @media screen and (max-width: 991px) {
    left: 10%;
  }
  @media screen and (max-width: 767px) {
    left: 0;
  }
`;

const Footer = () => {
  const data: FooterQuery = useStaticQuery(query);
  const menuLinks = data.menu.links;
  const logoImg = new Img(data.logoImage);
  return (
    <footer
      style={{
        background: colors.secondary.getHex(),
        width: `100%`,
        padding: `4vh 3vw`,
        borderTop: `3px solid ${colors.accent.toString()}`
      }}>
      <Container>
        <StyledFooterRow style={{ justifyContent: "space-around" }}>
          <Col sm={12} md="auto">
            <Logotype />
            <Row>
              <Col style={{ display: "flex", marginTop: "1rem" }}>
                <WmkImage image={logoImg} style={{ width: "3rem" }} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                  }}>
                  <Typography.Copy
                    style={{
                      fontSize: "13px",
                      // lineHeight: 2.5,
                      // paddingLeft: "13rem",
                      color: colors.white.getHex(),
                      paddingRight: 0,
                      width: "16rem",
                      display: "flex",
                      alignSelf: "center",
                      justifyContent: "flex-end"
                    }}>
                    {"Benefiting"}
                  </Typography.Copy>
                  <Typography.Copy
                    style={{
                      fontSize: "17px",
                      // lineHeight: 2.5,
                      // paddingLeft: "1rem",
                      color: colors.white.getHex(),
                      paddingRight: 0,
                      width: "16rem",
                      display: "flex",
                      alignSelf: "center",
                      justifyContent: "flex-end"
                    }}>
                    {`Children's Hospital Colorado`}
                  </Typography.Copy>
                </div>
              </Col>
            </Row>
          </Col>
          <FooterColumn menu={menuLinks[0]} index={0} />
          <FooterColumn menu={menuLinks[1]} index={1} />
          <FooterButtons menu={data.buttons} />
        </StyledFooterRow>
        <Row>
          <WatermarkCol>
            <TextLink.Plain
              to="https://wmkagency.com/"
              color={{
                normal: colors.accent.toString(),
                hover: colors.primary.toString()
              }}>
              Designed by Watermark
            </TextLink.Plain>
          </WatermarkCol>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;

interface FooterQuery {
  site: SiteMetaDataFields;
  menu: MenuQuery;
  buttons: MenuQuery;
  logoImage: ContentfulImageQuery;
}

const query = graphql`
  {
    site {
      ...NodeSiteMetadata
    }
    menu: contentfulMenu(title: { eq: "Footer Menu" }) {
      ...NodeMenuFields
    }
    buttons: contentfulMenu(title: { eq: "Footer Button Menu" }) {
      ...NodeMenuFields
    }
    logoImage: contentfulAsset(
      title: { eq: "Children-s Hospital Colorado circle" }
    ) {
      ...NodeImageQuery
    }
  }
`;
