import * as React from "react";
import { MenuQuery } from "../../../fragments/NodeMenuFields";
import { TextLink } from "../../ui/TextLink";

const Dropdown = ({ menu, index }: { menu: MenuQuery; index: number }) => {
  return (
    <TextLink.Menu index={index} to={"#"} dropdown={menu.links}>
      Events
    </TextLink.Menu>
  );
};

export default Dropdown;
