import "./node_modules/bootstrap/dist/css/bootstrap.css";
import Layout from "./src/components/layout/MainLayout";
import { LayoutProvider } from "./src/context/LayoutContext";
import * as React from "react";
import type { GatsbyBrowser } from "gatsby";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element
}) => {
  return (
    <LayoutProvider mobileBreakPoint={740}>
      <Layout>{element}</Layout>
    </LayoutProvider>
  );
};
