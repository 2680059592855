import * as React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import { colors } from "./colors";

const StyledLogoType = styled(Row)<{ mobile: "true" }>`
  line-height: 1;
  a {
    text-decoration: none;
    cursor: pointer;
  }
  strong {
    font-size: ${({ mobile }) => (mobile ? `1.2rem` : `1.6rem`)};
    color: ${colors.primary.toString()};
    font-weight: 900;
  }
  s {
    font-size: ${({ mobile }) => (mobile ? `1.2rem` : `1.6rem`)};
    color: ${colors.accent.toString()};
    font-weight: 900;
  }
  span {
    padding-left: 1px;
    font-size: ${({ mobile }) => (mobile ? `.9rem` : `1.2rem`)};
    color: ${colors.primary.toString()};
    letter-spacing: ${({ mobile }) => (mobile ? `16.25px` : `21px`)};
  }
`;

const Logotype = ({ isMobile }: { isMobile?: boolean }) => {
  return (
    <StyledLogoType
      className="flex-column"
      mobile={isMobile ? "true" : undefined}>
      <WmkLink to="/">
        <Col>
          <strong>BREAK</strong> <s>THE STIGMA</s>
        </Col>
        <Col>
          <span>COLORADO</span>
        </Col>
      </WmkLink>{" "}
    </StyledLogoType>
  );
};

export default Logotype;
