import Color from "ts-color-class";

const primary = new Color("#91F2C8");
const secondary = new Color("#007799");
const accent = new Color("#C0D000");
const tertiary = new Color("#44C694");

export const colors = {
  red: new Color("#E25534"),
  blue: secondary,
  primary,
  secondary,
  teal: primary,
  green: tertiary,
  darkGreen: new Color("#167F77"),
  tertiary,
  lightGreen: accent,
  oliveGreen: new Color(`#919d16`),
  accent,
  white: new Color("#FFFFFF"),
  gray: new Color("#E1E1E1"),
  black: new Color("#3a3a3a"),
  brightGreen: new Color(`#23FFA3`),
  orange: new Color(`#F09642`),
  rust: new Color(`#C55E00`),
  darkTeal: new Color(`#489B72`),
  lightBlue: new Color(`#75C7D2`)
};
