import * as React from "react";
import styled from "styled-components";
import { LinkTarget, WmkLink } from "wmk-link";
import { colors } from "../ui/colors";
import { Typography } from "../ui/Typography";

const StyledButton = styled(WmkLink)`
  display: inline-block;
  position: relative;
  background: ${colors.red.getHex()};
  color: ${colors.white.getHex()};
  padding: 0.2rem 1.25rem;
  transition: all 0.3s ease;
  border-radius: 10px;
  border: 2px solid ${colors.red.getHex()};
  text-decoration: none;
  &:hover {
    border: 2px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0px 3px 5px rgba(49, 50, 98, 0.7);
    color: ${colors.white.getHex()};
    transition: all 0.3s ease;
  }
`;

const FooterButton = ({
  to,
  target,
  children,
  tel,
  mailto
}: {
  to: string;
  target?: LinkTarget;
  children: React.ReactNode;
  tel?: boolean;
  mailto?: boolean;
}) => {
  return (
    <StyledButton
      to={to}
      target={target}
      tel={tel ? tel : undefined}
      mailto={mailto ? mailto : undefined}>
      <Typography.Button>{children}</Typography.Button>
    </StyledButton>
  );
};

export default FooterButton;