import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { MenuQuery } from "../../../fragments/NodeMenuFields";
import FooterButton from "../../Buttons/FooterButton";
import { colors } from "../../ui/colors";
import { TextLink } from "../../ui/TextLink";

const FooterButtons = ({ menu }: { menu: MenuQuery }) => {
  return (
    <Col sm={12} md="auto">
      {menu.links.map((link, i) =>
        link.__typename === "ContentfulPage" ? (
          <Row key={link.title + i}>
            <TextLink.Plain
              underline
              to={`/${link.slug}`}
              color={{
                normal: colors.accent.toString(),
                hover: colors.primary.toString()
              }}>
              {link.title}
            </TextLink.Plain>
          </Row>
        ) : link.__typename === "ContentfulLink" ? (
          <Row key={link.linkText + i} style={{ padding: `.5rem 0` }}>
            <FooterButton
              to={link.url.replace("self://", "/")}
              target={
                link.url.match("self://")
                  ? undefined
                  : link.target === "Same Window"
                  ? "self"
                  : "blank"
              }>
              {link.linkText}
            </FooterButton>
          </Row>
        ) : null
      )}
    </Col>
  );
};

export default FooterButtons;
