import * as React from "react";
import { MenuQueryLinks } from "../../../fragments/NodeMenuFields";
import { Col, Row } from "react-bootstrap";
import { TextLink } from "../../ui/TextLink";
import { getEventPath } from "../../../logic/paths";
import { MenuQuery } from "../../../fragments/NodeMenuFields";
import { colors } from "../../ui/colors";
import styled from "styled-components";

const LinkRow = styled(Row)`
  &.event-div {
    width: 14rem !important;
  }
`;

const FooterColumn = ({
  menu,
  index
}: {
  menu: MenuQueryLinks;
  index: number;
}) => {
  const { contentfulparent, links } = menu as MenuQuery;
  return (
    <Col sm={12} md="auto" style={{ paddingLeft: `2vw`, paddingRight: `2vw` }}>
      <Row>
        <TextLink.Menu
          static={!contentfulparent ? true : false}
          index={index}
          to={contentfulparent ? `/${contentfulparent?.slug}` : undefined}>
          {contentfulparent?.title ? contentfulparent.title : "Events"}
        </TextLink.Menu>
      </Row>
      <Row className="flex-column">
        {links.map((link, i) => (
          <Col key={link.title + i} style={{ padding: `.3rem 0 .2rem 1.5rem` }}>
            <LinkRow
              style={{ width: "100%" }}
              className={
                link.__typename === "ContentfulEvent" ? "event-div" : ""
              }>
              <TextLink.Plain
                target={
                  link.__typename === "ContentfulSponsor"
                    ? "blank"
                    : link.__typename === "ContentfulLink"
                    ? link.target === "New Window"
                      ? "blank"
                      : "self"
                    : undefined
                }
                to={
                  link.__typename === "ContentfulEvent"
                    ? getEventPath(link)
                    : link.__typename === "ContentfulSponsor"
                    ? link.url
                    : link.__typename === "ContentfulLink"
                    ? link.url.replace("self://", "/")
                    : link.__typename === "ContentfulPage"
                    ? `/${link.slug}`
                    : "#"
                }
                color={{
                  hover: colors.primary.toString(),
                  normal: colors.white.toString()
                }}>
                {link.title}
              </TextLink.Plain>
            </LinkRow>
            {link.__typename === "ContentfulSponsor" ? (
              <Row style={{ lineHeight: 1 }}>
                <TextLink.Phone alt>{link.phone}</TextLink.Phone>
              </Row>
            ) : null}
          </Col>
        ))}
      </Row>
    </Col>
  );
};

export default FooterColumn;
