import * as React from "react";
import { WmkLink, WmkLinkProps } from "wmk-link";
import styled from "styled-components";
import { colors } from "./colors";
import { useLayoutContext } from "../../context/LayoutContext";
import { BiRightArrowAlt, BiLeftArrowAlt, BiCaretDown } from "react-icons/bi";
import { MenuQueryLinks } from "../../fragments/NodeMenuFields";
import { useState, useRef, useEffect } from "react";
import { getEventPath } from "../../logic/paths";
import { Col, Row } from "react-bootstrap";

const StyledDropdownContents = styled(Row)<{ box?: DOMRect }>`
  position: absolute;
  top: ${({ box }) => `calc(${box?.bottom}px + 1.75rem)` || 0};
  background: ${colors.white.toString()};
  border-left: 4px solid ${colors.primary.toString()};
  right: ${({ box }) => box?.right || 0};
`;

export interface TextLinkDropdownProps extends WmkLinkProps {
  alt?: boolean;
}

const StyledTextLinkDropdown = styled(WmkLink)<{ mobile: 1 | 0 }>`
  font-family: "Work Sans", sans-serif;
  text-decoration: none;
  color: ${({ mobile }) =>
    mobile ? colors.white.toString() : colors.secondary.toString()};
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    color: ${colors.green.toString()};
    transition: all 0.3s ease;
  }
  font-size: ${({ mobile }) => (mobile ? `1.5rem` : `1rem`)};
  @media screen and (max-width: 1000px) {
  color: ${colors.white.toString()};
  }
`;

export interface TextLinkMenuProps extends WmkLinkProps {
  index: number;
  static?: boolean;
  dropdown?: MenuQueryLinks[];
}

const StyledTextLinkMenu = styled(WmkLink)<{
  index: number;
  mobile: 1 | 0;
  static?: "true";
}>`
  position: relative;
  color: ${({ mobile }) =>
    mobile ? colors.white.toString() : colors.brightGreen.toString()};
  font-family: "Work Sans", sans-serif;
  text-decoration: none;
  ${({ mobile }) => (!mobile ? `border-bottom: 3px solid;` : ``)}
  ${(props) => (!props.static ? `cursor: pointer;` : `cursor: default;`)}
  transition: all 0.3s ease;
  &:hover {
    color: ${(props) =>
      props.static
        ? props.mobile
          ? colors.white.toString()
          : colors.brightGreen.toString()
        : colors.accent.toString()};
    transition: all 0.3s ease;
  }
  ${({ index, mobile }) => {
    const mod = (index + 1) % 3;
    let color: string = "";
    switch (mod) {
      case 1:
        color = colors.primary.toString();
        break;
      case 2:
        color = colors.accent.toString();
        break;
      case 0:
        color = colors.brightGreen.toString();
        break;
    }
    return !mobile ? `border-color ${color};` : ``;
  }}
`;

const StyledTextLinkPhone = styled(WmkLink)<{ alt: 0 | 1 }>`
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  color: ${({ alt }) =>
    !alt ? colors.red.toString() : colors.accent.toString()};
  text-decoration: underline !important;
  transition: all 0.3s ease;
  &:hover {
    color: ${colors.primary.toString()};
    transition: all 0.3s ease;
  }
`;

export interface TextLinkPhoneProps extends WmkLinkProps {
  alt?: boolean;
}

const StyledTextLinkArrow = styled(WmkLink)<{ color?: LinkColor }>`
  cursor: pointer;
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  color: ${({ color }) => (color ? color.normal : colors.red.toString())};
  transition: all 0.3s ease;
  text-decoration: underline;
  &:hover {
    color: ${({ color }) =>
      color ? color.hover : colors.secondary.toString()};
    transition: all 0.3s ease;
  }
`;

interface LinkColor {
  normal: string;
  hover: string;
}

export interface TextLinkArrowProps extends WmkLinkProps {
  hideArrow?: boolean;
  color?: LinkColor;
  back?: boolean;
}

export interface TextLinkPlainProps extends WmkLinkProps {
  color?: LinkColor;
  underline?: boolean;
}

const StyledTextLinkPlain = styled(WmkLink)<{
  color?: LinkColor;
  underline?: "true";
}>`
  color: ${({ color }) => (color ? color.normal : colors.green.toString())};
  ${({ underline }) =>
    underline
      ? `text-decoration: underline; font-weight: 600;`
      : `text-decoration: none;`}
  transition: all 0.3s ease;
  font-family: "Work Sans", sans-serif;
  &:hover {
    color: ${({ color }) =>
      color ? color.hover : colors.secondary.toString()};
    transition: all 0.3s ease;
  }
`;

export const TextLink = {
  Menu: (props: TextLinkMenuProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [box, setBox] = useState<DOMRect>();
    const context = useLayoutContext();
    const linkRef = useRef<HTMLDivElement>();
    useEffect(() => {
      const target = linkRef.current?.getBoundingClientRect();
      setBox(target);
    }, []);
    return (
      <>
        <StyledTextLinkMenu
          {...props}
          to={props.to ? props.to : "#"}
          mobile={context.isMobile ? 1 : 0}
          static={props.static ? "true" : undefined}
          ref={linkRef as any}
          onClick={
            props.dropdown
              ? (e) => {
                  e.preventDefault();
                  setIsOpen(!isOpen);
                }
              : undefined
          }>
          {props.children}
          {props.dropdown ? (
            <BiCaretDown
              style={{
                transform: !isOpen ? `rotate(0)` : `rotate(180deg)`,
                transition: `all .3s ease`
              }}
            />
          ) : null}
        </StyledTextLinkMenu>
        <StyledDropdownContents
          className="flex-column"
          box={box}
          style={isOpen ? { padding: `0.8rem 0.2rem` } : undefined}>
          {props.dropdown && isOpen
            ? props.dropdown.map((link, i) => {
                return link.__typename === "ContentfulEvent" ? (
                  <Col
                    key={link.title + i}
                    style={{ padding: `.25rem .5rem .25rem .75rem` }}>
                    <TextLink.Dropdown
                      to={getEventPath(link)}
                      onClick={() => setIsOpen(false)}>
                      {link.title}
                    </TextLink.Dropdown>
                  </Col>
                ) : null;
              })
            : null}
        </StyledDropdownContents>
      </>
    );
  },
  Dropdown: (props: TextLinkDropdownProps) => {
    const context = useLayoutContext();
    return (
      <StyledTextLinkDropdown
        {...props}
        mobile={props.alt || context.isMobile ? 1 : 0}>
        {props.children}
      </StyledTextLinkDropdown>
    );
  },
  Phone: (props: TextLinkPhoneProps) => {
    const phone = props.children as string;
    return (
      <StyledTextLinkPhone {...props} tel alt={props.alt ? 1 : 0}>
        {phone}
      </StyledTextLinkPhone>
    );
  },
  Arrow: (props: TextLinkArrowProps) => {
    return (
      <StyledTextLinkArrow {...props}>
        {props.hideArrow || !props.back ? null : <BiLeftArrowAlt />}
        <span
          style={
            !props.hideArrow
              ? !props.back
                ? { display: `inline-block`, marginRight: `5px` }
                : { display: `inline-block`, marginLeft: `3px` }
              : undefined
          }>
          {props.children}
        </span>
        {props.hideArrow || props.back ? null : <BiRightArrowAlt />}
      </StyledTextLinkArrow>
    );
  },
  Plain: (props: TextLinkPlainProps) => (
    <StyledTextLinkPlain
      {...props}
      underline={props.underline ? "true" : undefined}>
      {props.children}
    </StyledTextLinkPlain>
  )
};
