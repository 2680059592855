import React, {
  ReactNode,
  useState,
  createContext,
  useContext,
  useEffect
} from "react";

export interface LayoutContextData {
  isMobile: boolean;
  isFullHeader: boolean;
}

const LayoutContext = createContext<LayoutContextData | undefined>(undefined);

const LayoutProvider = ({
  children,
  mobileBreakPoint
}: {
  children: ReactNode;
  mobileBreakPoint: number;
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isFullHeader, setIsFullHeader] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < mobileBreakPoint);
      setIsFullHeader(window.innerWidth > 1010);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <LayoutContext.Provider value={{ isMobile, isFullHeader }}>
      {children}
    </LayoutContext.Provider>
  );
};

const useLayoutContext = () => {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error("useHubUserState must be used within a HubUserProvider");
  }
  return context;
};

export { useLayoutContext, LayoutContext, LayoutProvider };
