import * as React from "react";

export const FONT_FAMILY_REGULAR = `Urbane, sans-serif`;

const FONT_WEIGHT_LIGHT = 300;
// const FONT_WEIGHT_NORMAL = 400;
const FONT_WEIGHT_MEDIUM = 500;
const FONT_WEIGHT_SEMIBOLD = 600;
const FONT_WEIGHT_BOLD = 700;
const FONT_WEIGHT_HEAVY = 900;

const FONT_SIZE_H1_DESKTOP = 80;
// const FONT_SIZE_H1_MOBILE = 40;
// const FONT_SIZE_H1_SUBHEADER_DESKTOP = 35;
// const FONT_SIZE_H1_SUBHEADER_MOBILE = 24;
const FONT_SIZE_H2 = 50;
const FONT_SIZE_H3 = 50;
const FONT_SIZE_H4 = 45;
const FONT_SIZE_H5 = 30;
const FONT_SIZE_H6 = 25;
const BODY_COPY = 20;
const NAV_DESKTOP = 17;
const BUTTONS = 16;

export const Typography = {
  H1: React.forwardRef(
    (
      {
        children,
        style,
        subheader,
        // mobile
      }: {
        children: React.ReactNode;
        style?: React.CSSProperties;
        subheader?: React.ReactNode;
        // mobile: boolean;
      },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h1
        style={{
          fontFamily: FONT_FAMILY_REGULAR,
          fontWeight: FONT_WEIGHT_HEAVY,
          fontSize: FONT_SIZE_H1_DESKTOP,
          ...style
        }}
        ref={ref}>
        {children}
        {subheader && (
          <span
            style={{
              fontSize: FONT_SIZE_H1_DESKTOP,
              fontWeight: FONT_WEIGHT_MEDIUM,
              textDecoration: "strikethrough"
            }}>
            {subheader}
          </span>
        )}
      </h1>
    )
  ),
  H2: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h2
        style={{
          fontFamily: FONT_FAMILY_REGULAR,
          fontWeight: FONT_WEIGHT_SEMIBOLD,
          fontSize: FONT_SIZE_H2,
          ...style
        }}
        ref={ref}>
        {children}
      </h2>
    )
  ),
  H3: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h3
        style={{
          fontFamily: FONT_FAMILY_REGULAR,
          fontWeight: FONT_WEIGHT_LIGHT,
          fontSize: FONT_SIZE_H3,
          ...style
        }}
        ref={ref}>
        {children}
      </h3>
    )
  ),
  H4: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h4
        style={{
          fontFamily: FONT_FAMILY_REGULAR,
          fontWeight: FONT_WEIGHT_HEAVY,
          fontSize: FONT_SIZE_H4,
          ...style
        }}
        ref={ref}>
        {children}
      </h4>
    )
  ),
  H5: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h5
        style={{
          fontFamily: FONT_FAMILY_REGULAR,
          fontWeight: FONT_WEIGHT_BOLD,
          fontSize: FONT_SIZE_H5,
          ...style
        }}
        ref={ref}>
        {children}
      </h5>
    )
  ),
  H6: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h6
        style={{
          fontFamily: FONT_FAMILY_REGULAR,
          fontWeight: FONT_WEIGHT_MEDIUM,
          fontSize: FONT_SIZE_H6,
          ...style
        }}
        ref={ref}>
        {children}
      </h6>
    )
  ),
  Copy: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLDivElement>
    ) => (
      <div
        style={{
          fontFamily: FONT_FAMILY_REGULAR,
          fontWeight: FONT_WEIGHT_LIGHT,
          fontSize: BODY_COPY,
          ...style
        }}
        ref={ref}>
        {children}
      </div>
    )
  ),
  CopyBold: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLDivElement>
    ) => (
      <div
        style={{
          fontFamily: FONT_FAMILY_REGULAR,
          fontWeight: FONT_WEIGHT_SEMIBOLD,
          fontSize: BODY_COPY,
          ...style
        }}
        ref={ref}>
        {children}
      </div>
    )
  ),
  NavDesktop: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLDivElement>
    ) => (
      <div
        style={{
          fontFamily: FONT_FAMILY_REGULAR,
          fontWeight: FONT_WEIGHT_MEDIUM,
          fontSize: NAV_DESKTOP,
          ...style
        }}
        ref={ref}>
        {children}
      </div>
    )
  ),
  Button: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: React.CSSProperties },
      ref: React.ForwardedRef<HTMLDivElement>
    ) => (
      <div
        style={{
          fontFamily: FONT_FAMILY_REGULAR,
          fontWeight: FONT_WEIGHT_SEMIBOLD,
          fontSize: BUTTONS,
          ...style
        }}
        ref={ref}>
        {children}
      </div>
    )
  )
};
