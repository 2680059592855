import * as React from "react";
import { colors } from "../../ui/colors";
import { Col, Container, Row } from "react-bootstrap";
import Logotype from "../../ui/Logotype";
import { graphql, useStaticQuery } from "gatsby";
import { MenuQuery } from "../../../fragments/NodeMenuFields";
import Dropdown from "./Dropdown";
import { TextLink } from "../../ui/TextLink";
import FooterButton from "../../Buttons/FooterButton";
import { useLayoutContext } from "../../../context/LayoutContext";
import BurgerButton from "./BurgerButton";
import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Typography } from "../../ui/Typography";
import { getEventPath } from "../../../logic/paths";

const MenuCol = ({ children }: { children: React.ReactNode }) => (
  <Col
    xs="auto"
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end"
    }}>
    {children}
  </Col>
);

const FullDesktopHeader = ({ menu }: { menu: MenuQuery }) => (
  <Col
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end"
    }}>
    <Row
      style={{
        width: `100%`,
        alignItems: "center",
        justifyContent: "flex-end"
      }}>
      {menu.links.map((link, i) => {
        return link.__typename === "ContentfulMenu" ? (
          <MenuCol key={link.title + i}>
            <Dropdown menu={link} index={i} />
          </MenuCol>
        ) : link.__typename === "ContentfulPage" ? (
          <MenuCol key={link.title + i}>
            <TextLink.Menu to={`/${link.slug}`} index={i}>
              {link.title}
            </TextLink.Menu>
          </MenuCol>
        ) : link.__typename === "ContentfulLink" ? (
          link.linkText.match(/donate/i) || link.linkText.match(/home/i) ? (
            <MenuCol key={link.linkText + i}>
              <TextLink.Menu
                to={link.url.includes("self:///")
                ? link.url.replace("self:///", "/")
                : link.url.includes("self://")
                ? link.url.replace("self://", "/")
                : link.url}
                target={
                  link.url.match("self://")
                    ? undefined
                    : link.target === "Same Window"
                    ? "self"
                    : "blank"}
                index={i}>
                {link.linkText}
              </TextLink.Menu>
            </MenuCol>
          ) : (
            <MenuCol key={link.linkText + i}>
              <FooterButton
                to={
                  link.url.includes("self:///")
                    ? link.url.replace("self:///", "/")
                    : link.url.replace("self://", "/")
                }
                target={
                  link.url.match("self://")
                    ? undefined
                    : link.target === "Same Window"
                    ? "self"
                    : "blank"
                }>
                {link.linkText}
              </FooterButton>
            </MenuCol>
          )
        ) : null;
      })}
    </Row>
  </Col>
);

const BurgerDrawer = styled.div<{ top: number }>`
  position: fixed;
  top: ${({ top }) => top}px;
  left: 0;
  height: calc(100vh);
  background: ${colors.blue.toString()};
`;

const MobileBurgerMenu = ({
  menu,
  height
}: {
  menu: MenuQuery;
  height: number;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const close = () => {
    setIsOpen(false);
  };
  return (
    <>
      <Col
        xs="auto"
        style={{
          display: "flex",
          alignItems: "center",
          // justifyContent: "flex-end",
          padding: `0 2vh 0 0`
        }}>
        <div style={{ padding: `0` }}>
          <BurgerButton isStuck={false} isOpen={isOpen} toggle={toggle} />
        </div>
      </Col>
      {isOpen ? (
        <BurgerDrawer top={height}>
          <Container style={{ padding: `5vh 1vh` }}>
            {/* <Row style={{ padding: 0, margin: 0 }}>
              <TextLink.Dropdown
                to="/"
                onClick={close}
                style={{ lineHeight: 1.75 }}>
                Home
              </TextLink.Dropdown>
            </Row> */}
            <Row className="flex-column">
              {menu.links.map((link, i) => {
                return link.__typename === "ContentfulPage" ? (
                  <TextLink.Dropdown
                    style={{ lineHeight: 1.75 }}
                    onClick={close}
                    key={link.title + i}
                    to={`/${link.slug}`}>
                    {link.title}
                  </TextLink.Dropdown>
                ) : link.__typename === "ContentfulLink" ? (
                  link.linkText === "Get Involved" ? (
                    <div
                      key={link.url + i}
                      style={{ margin: `2vh 0` }}
                      onClick={close}>
                      <FooterButton
                        target={link.url.match("self://") ? undefined : "blank"}
                        to={link.url.replace("self://", "/")}>
                        {link.linkText}
                      </FooterButton>
                    </div>
                  ) : (
                    <TextLink.Dropdown
                      style={{ lineHeight: 1.75 }}
                      to={link.url.replace("self://", "")}
                      key={link.url + i}
                      target={link.url.match("self://") ? undefined : "blank"}
                      onClick={close}>
                      {link.linkText}
                    </TextLink.Dropdown>
                  )
                ) : link.__typename === "ContentfulMenu" ? (
                  <div key={link.title + i}>
                    <Typography.CopyBold
                      style={{
                        lineHeight: 1.75,
                        fontSize: `1.5rem`,
                        color: colors.primary.toString()
                      }}
                      key={link.title + i}>
                      Events
                    </Typography.CopyBold>
                    <Row style={{ margin: `2vh 1rem` }} className="flex-column">
                      {link.links.map((l, i) => {
                        return l.__typename === "ContentfulEvent" ? (
                          <TextLink.Dropdown
                            style={{ lineHeight: 1.75 }}
                            onClick={close}
                            to={getEventPath(l)}
                            key={l.title + i}>
                            {l.title}
                          </TextLink.Dropdown>
                        ) : null;
                      })}
                    </Row>
                  </div>
                ) : null;
              })}
            </Row>
            <Row>
              <TextLink.Arrow
                hideArrow
                color={{
                  normal: colors.accent.toString(),
                  hover: colors.primary.toString()
                }}
                to="/contact-us"
                onClick={close}>
                Contact Us
              </TextLink.Arrow>
            </Row>
            {/* <Row></Row>
            <Row></Row> */}
          </Container>
        </BurgerDrawer>
      ) : null}
    </>
  );
};

const HeaderInnerContainer = styled(Container)`
  margin: 0;
  padding: 0 5vw;
  @media screen and (max-width: 740px) {
    padding: 0;
  }
`;

const Header = () => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const menu: MenuQuery = useStaticQuery(query).contentfulMenu;
  const context = useLayoutContext();
  const headerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleResize = () => {
    const target = headerRef.current?.getBoundingClientRect();
    setHeaderHeight(target?.height ? target.height : 0);
  };

  return (
    <Container
      ref={headerRef as any}
      fluid
      style={{
        background: colors.secondary.toString(),
        borderBottom: `3px solid ${colors.accent.toString()}`,
        padding: 0,
        margin: 0,
        maxWidth: `100vw`
      }}>
      <HeaderInnerContainer fluid>
        <Row
          style={{
            padding: 0,
            margin: 0,
            maxWidth: `100vw`,
            justifyContent: "space-between"
          }}>
          <Col style={{ padding: `2vh` }} xs="auto">
            <Logotype isMobile={!context.isFullHeader} />
          </Col>
          {context.isFullHeader ? (
            <FullDesktopHeader menu={menu} />
          ) : (
            <MobileBurgerMenu menu={menu} height={headerHeight} />
          )}
        </Row>
      </HeaderInnerContainer>
    </Container>
  );
};

export default Header;

const query = graphql`
  {
    contentfulMenu(title: { eq: "Main Menu" }) {
      ...NodeMenuFields
    }
  }
`;
